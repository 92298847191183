import React from 'react';
import '../assets/css/spinner.css';

const Spinner = () => {
  return (
    <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
  );
};

export default Spinner;
